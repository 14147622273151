// JavaScript Document

// vendor

import jQuery from "jquery";

import jQueryBridget from "jquery-bridget";

import Isotope from "isotope-layout";
import Masonry from "masonry-layout";

jQueryBridget("isotope", Isotope, jQuery);
jQueryBridget("masonry", Masonry, jQuery);

// custom
jQuery(document).ready(function () {

    if (document.documentMode === 10) {
        document.documentElement.className += " ie10";
    }
    else if (document.documentMode === 11) {
        document.documentElement.className += " ie11";
    }

    jQuery(".menu-toggle").click(function () {
        jQuery("html").toggleClass("nav-open");
    });

    jQuery(".close-icon").on("click touchstart",function () {
        jQuery("html").removeClass("nav-open");
    });

    jQuery(".menu-toggle").click(function () {
        jQuery("body").toggleClass("menu-open");
    });
    jQuery(".overlay").on("click touchstart",function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".header-menu ul li").removeClass("open");
    });
    jQuery(".close-icon").on("click touchstart",function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".header-menu ul li").removeClass("open");
    });

    jQuery(".main-navigation .sub-menu").parent().prepend("<span class=\"menu-arrow\"><i class=\"fas fa-angle-down\"></i></span>");
    jQuery(".main-navigation .menu li > span.menu-arrow").on("click touchstart", function (event) {
        jQuery(this).toggleClass("active").parent().siblings().children("span").removeClass("active");
        event.preventDefault();
        event.stopPropagation();
        jQuery(this).parent().toggleClass("open").siblings().removeClass("open");

    });

    const $grid = jQuery(".grid-item");
    $grid.isotope({
        itemSelector: ".item",
        layoutMode: "fitRows",
        masonry: {
            itemSelector: ".item",
            percentPosition: true
        }
    });

    jQuery(".filters-button-group").on( "click", "button", function () {
        const filterValue = jQuery(this).attr("data-filter");
        $grid.isotope({ filter: filterValue });
    });
});
